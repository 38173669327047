import './mainStyle.css'
import axios from '../router/ApiService'
import alert from '../Alerter'

const model = {
    activeButton: 0,
    activeSlider: false,
    activeItemSlider: 'Home',
    dataName:''
};


export default {
    data: () => (model),
    watch:{
        activeItemSlider:function (){
            if (this.activeItemSlider!==this.$router.name){
                this.$router.push('/'+this.activeItemSlider)
            }
        }
    },
    methods: {
        async sendNotification() {
            const form = new URLSearchParams();
            form.append('id', 181);
            form.append('title', 'Hi MTD!!!');
            form.append('message', 'I,m Vue.js');

            await axios(sessionStorage.token).post('user/sendnotif', form)
                .then((res) => {
                    alert.openNotification(this.$vs, 'Success', res.data.message, 'success')
                }).catch((e) => {
                    alert.openNotification(this.$vs, 'Error', e.message, 'danger')
                });
        }
    },
    name: "mainPage"
}